.Main {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}
