a {
  text-decoration: underline;
  color: white;
}

.App {
  height: 100%;
  width: 100%;
}

.Home {
  width: 100%;
  min-height: 100vh;
  color: white;
  flex-direction: column;
  flex-basis: auto;
  flex-wrap: nowrap;
  display: flex;
}

.description {
  font-size: large;
}

.question {
  font-size: larger;
  font-weight: 700;
}

.solution {
  padding-top: 15px;
  font-size: medium;
}