.Footer {
  text-align: center;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}
